import { Inject, Injectable, InjectionToken } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpHeaderResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private baseUrl: string = environment.baseUrl;

  constructor(private httpClient: HttpClient, public toastr: ToastrService) {}
  fetchTransactions(): Observable<any> {
    return this.httpClient.get<any>(`api/transaction`).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  fetchTransaction(txnId: any): Observable<any> {
    return this.httpClient.get<any>(`api/transaction/${txnId}`).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  createSetupIntent(body: any): Observable<any> {
    const headers = new HttpHeaders().set('x-user-id', body);

    return this.httpClient
      .post<any>('api/payments/create-setup-intent/',{ payment_method_type: 'card' }, {headers})
      .pipe(
        map((response: any) => response),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  savePaymentMethod(data: any): Observable<any> {
    return this.httpClient
      .post<any>('api/payments/save-payment-method/', data)
      .pipe(
        map((response: any) => response),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  getCardDetails(): Observable<any> {
    return this.httpClient.get<any>(`api/payments/list-payment-methods/`).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  //profile-update details
  getUser(): Observable<any> {
    return this.httpClient.get<any>(`api/users/`).pipe(
      map((response) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  updateUserDetails(first_name: string, last_name: string) {
    const body = {
      first_name: first_name,
      last_name: last_name,
    };

    return this.httpClient.put<any>(`api/users/`, body).pipe(
      map((response) => {
        return response;
      }),
      tap(() => {
        this.toastr.success('User details updated successfully');
      }),
      catchError((error) => {
        this.toastr.error('Not able to update username', error.message);
        return throwError(() => error);
      })
    );
  }

  setDefaultCard(body: any): Observable<any> {
    return this.httpClient
      .put<any>(`api/payments/set-default-payment-method/${body}`, {})
      .pipe(
        map((response: any) => response),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

//   deleteUploadFile(body: any): Observable<any> {
//     console.log("body-API",body);
    
//     return this.httpClient
//       .delete<any>('api/transaction/document/' , body)
//       .pipe(
//         map((response: any) => response),
//         catchError((error: any) => {
//           return throwError(() => error);
//         })
//       );
//   }

deleteUploadFile(body: any): Observable<any> {
    console.log("body-API", body);
  
    return this.httpClient
      .delete<any>('api/transaction/document/', {
        body: body,
      })
      .pipe(
        map((response: any) => response),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }


  deleteCard(body: any): Observable<any> {
    return this.httpClient
      .delete<any>(`api/payments/delete-payment-method/${body}`)
      .pipe(
        map((response: any) => response),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  saveFeedback(body: any): Observable<any> {
    return this.httpClient.post<any>('api/transaction/feedback/', body).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  downloadTxnFile(path: any): Observable<any> {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        path: path,
      },
      responseType: 'blob' as 'json',
    };
    return this.httpClient.get(
      `api/transaction/document/download/`,
      requestOptions
    );
  }

  inviteUser(body: any): Observable<any> {
    const getUserData = localStorage.getItem('user-data');
    let headers = new HttpHeaders();

    if (getUserData) {
      const data = JSON.parse(getUserData);
      const user_id = data.user_id;

      // Add user_id to headers
      headers = headers.set('user_id', user_id);
    }

    return this.httpClient.post<any>('api/users/', body, { headers }).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getBillDetails(): Observable<any> {
    const userDataString = localStorage.getItem('user-data');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const agencyId = userData.agency_id;
  
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'agency-id': "acy-6a881d04", 
        'agency-id': agencyId
      });
      return this.httpClient.get<any>('api/payments/get-billing-details', { headers }).pipe(
        map((response: any) => {
            return response;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
    } else{
        return throwError(() => new Error('agencyId not found'))
    }
  }
  
  // Fetch client secret from the backend
  fetchClientSecret(userId : any): Observable<string> {
    const headers = new HttpHeaders().set('x-user-id', userId);
    return this.httpClient
      .post<any>(`api/payments/create-setup-intent/`, { payment_method_type: 'us_bank_account' }, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  getInvoiceDetails(): Observable<any> {
    return this.httpClient.get<any>(`api/txn-billing/invoice/`).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
  
  getInvoice(): Observable<any> {
    return this.httpClient.get<any>(`api//txn-billing/invoice/details/`).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}
