import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './shared/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth/service/auth.service';
import { environment } from '../environments/environment';
import { ShowRequestFormDailogComponent } from './modules/show-request-form-dailog/show-request-form-dailog.component';
import { LoaderComponent } from './shared/loader/loader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,CommonModule,
    RouterModule,LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Blue-pond';
  client: any;
  isLoading : boolean = false;
  showForm: boolean = false;
   constructor(
      public router: Router,
      public authService: AuthService,
      public toaster: ToastrService,
      public commonService: CommonService,
      private route: ActivatedRoute,
      private modalService: NgbModal
    ) { this.client = environment.client }
  ngOnInit(): void {
     // login through URL
  if (this.client == 'usg') {
    const hash = window.location.hash; // Get the hash part of the URL
    const queryString = hash.includes('?') ? hash.split('?')[1] : '';
    const urlParams = new URLSearchParams(queryString);

    const [first_name = '', last_name = ''] = (urlParams.get('agtnm') || '').split(' ');

    const formData = {
      first_name,
      last_name,
      email_id: urlParams.get('agtemail') || '',
      agency_name: urlParams.get('Agency') || '',
      usg_partner_id: '',
      state: '',
      agency_id: '',
    };

    if (
      urlParams.get('Agency') &&
      urlParams.get('agtnm') &&
      urlParams.get('agtemail')
    ) {
      this.isLoading = true;
      localStorage.clear()
      const email = urlParams.get('agtemail')!;
      this.commonService.setEmail(email);
      this.authService.getUserDetails(formData).subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res.status == 'Success') {
            if (res.data.status == 'show_token_page') {
              this.showForm = false;
            }
            if (res.data.status == 'show_token_page_with_signup') {
              this.showForm = true;
            }
            this.router.navigate(['/login/email-verification'], {
              queryParams: { showForm: this.showForm },
            });
            this.toaster.success(res.message, '', {
              timeOut: 5000,
              closeButton: true,
            });
          }
        },
        error: (err) => {
          this.isLoading = false;
          if (!err.error.success) {
            this.showForm = true;
            this.toaster.error(err.error.message, '', {
              timeOut: 5000,
              closeButton: true,
            });
            if (err.error.data.status == 'show request form') {
              this.updateUser(urlParams.get('Agency')!);
            }
          }
        },
      });
    }
  }
  }
   updateUser(agency_name:any): void {
      const modalRef  = this.modalService.open(ShowRequestFormDailogComponent, {
        centered: true,
        size: 'md',
        modalDialogClass: 'bs-modal-parent-class',
        keyboard: false, // Prevent closing by pressing the Escape key
        backdrop: 'static', // Prevent closing by clicking outside
        backdropClass: 'custom-backdrop',
      });

      modalRef.componentInstance.total_account_count =  0;
      modalRef.componentInstance.agency_name = agency_name || '';
      modalRef.componentInstance.onCloseRedirect = 'login';
    }
}
