import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient) { }

  private baseUrl: string = environment.baseUrl


  getUserDetails(body: any): Observable<any> {
    return this.httpClient.post<any>('auth/process-usg-partner', body ).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    )
  }

  updateAgencyDetails(body: any,headers:any): Observable<any> {
    return this.httpClient.put<any>(`api/users/agency/`, body,{headers}).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getVerificationCode(body: any, headers: any): Observable<any> {
    return this.httpClient.post<any>('auth/send-verification-code', body , { headers }).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  authenticateUser(body: any): Observable<any> {
    const params = new HttpParams({ fromObject: body });
    return this.httpClient.post<any>('auth/verification', params).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  createUser(data: any): Observable<any> {
    return this.httpClient.post<any>('auth/registration', data).pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getAgencyState(): Observable<any> {
    return this.httpClient.get<any>('auth/state').pipe(
      map((response: any) => response),
      catchError((error: any) => {
        return throwError(() => error);
      })
    )
  }

  acceptTC(body: any,headers: any): Observable<any> {
    const params = new HttpParams({ fromObject: body });
    // return this.httpClient.put<any>('api/users', params,{ headers }).pipe(
    //   map((response: any) => response),
    //   catchError((error: any) => {
    //     return throwError(() => error);
    //   })
    // );

    return this.httpClient.put<any>(`api/users/`, body,{headers}).pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => {
            return throwError(() => error);
          })
        );
  }

}
