<div class="custom_modal  card_confirm">
    <div class="modal-content">
      <div mat-dialog-title class="modal_title">
        <h2>{{modalTitle}}</h2>
      </div>
      <div class="modal_body" mat-dialog-content>

        <ng-container *ngIf="(modalName === 'softwareserviceagreement' || modalName === 'terms&condition' || modalName === 'privacy') && modalName !== 'video'">
          <iframe [src]="modalValue | safeUrl" width="100%" height="600px"></iframe>
        </ng-container>

        <div *ngIf="modalName === 'video'">
          <video width="100%" controls autoplay>
            <source [src]="modalValue | safeUrl" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>

        <p>{{modalBody}}</p>

            <div *ngIf="modalName === 'invite_user'">
              <ng-template #tooltipContent>
                You will only be able to invite user from your agency. (i.e. email address domain should be same)
               </ng-template>
              <form [formGroup]="inviteUserForm">
                <div>
                  <div class="row ">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="inviteUserForm">First Name<span class="require-field">*</span></label>
                        <div class="d-flex">
                          <input type="text" class="form-control" maxlength="100" minlength="2"
                            [ngClass]="{'custom-is-invalid': isSubmitted && inviteUserFormControl['first_name'].errors}"
                            id="inviteUserForm" placeholder="Enter First Name" formControlName="first_name" (input)="onChange($event,'first_name')">
                        </div>
                        <div *ngIf="isSubmitted && inviteUserFormControl['first_name'].errors" class="form-text text-danger">
                          <span *ngIf="inviteUserFormControl['first_name'].errors['required']">Please enter first name.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="inviteUserForm">Last Name<span class="require-field">*</span></label>
                        <div class="d-flex">
                          <input type="text" class="form-control" maxlength="100" minlength="2"
                            [ngClass]="{'custom-is-invalid': isSubmitted && inviteUserFormControl['last_name'].errors}" id="inviteUserForm"
                            placeholder="Enter last name" formControlName="last_name" (input)="onChange($event,'last_name')">
                        </div>
                        <div *ngIf="isSubmitted && inviteUserFormControl['last_name'].errors" class="form-text text-danger">
                          <span *ngIf="inviteUserFormControl['last_name'].errors['required']">Please enter last name.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row ">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-label" for="inviteUserForm">Email<span class="require-field">*</span></label>

                        <img class="tooltip-icon" [ngbTooltip]="tooltipContent" maxlength="254"
                        tooltipClass="my-custom-class" placement="auto"
                        triggers="hover focus" src="../../../assets/images/info-filled.svg" width="24px" alt="">

                        <div class="input-group input-group-decode">
                          <!-- User input part -->
                          <input type="text" class="form-control" formControlName="email" name="inviteUserForm" id="inviteUserForm"
                            placeholder="Your email" [ngClass]="{'custom-is-invalid': (isSubmitted && inviteUserFormControl['email'].errors || checkSubDomain)}"
                            (input)="onInputChange($event)">

                          <!-- Static subdomain part -->
                          <span class="input-group-text">{{decodedDomain}}</span>
                        </div>
                        <div *ngIf="isSubmitted && inviteUserFormControl['email'].errors" class="form-text text-danger">
                          <span *ngIf="inviteUserFormControl['email'].errors['required']">Please enter the email address without the domain.</span>
                          <span *ngIf="inviteUserFormControl['email'].errors['pattern']">Please enter the email address without the domain.</span>
                        </div>
                        <div class="form-text text-danger">
                          <span *ngIf="checkSubDomain && !inviteUserFormControl['email'].errors">Please add user from your agency only.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
      </div>
      <div class="modal_footer">
              <button type="button" class="btn btn-light back-button btn-outline color-white btn-custom color-white" (click)="onCancel()"
              [innerText]="(modalName === 'softwareserviceagreement' ||modalName === 'terms&condition' || modalName === 'privacy' || modalName === 'invite_user' || modalName === 'session_expired' || modalName === 'FromSuccess-PopUp-Alert' || modalName === 'video') ? 'Close' : 'Cancel'"
              *ngIf="modalName !== 'confirmation-PopUp'  && modalName !== 'reset_dialog' && modalName !== 'deleteFile_dialog'">
            </button>
              <button _ngcontent-ng-c918428904="" type="submit" class="btn btn-light back-button btn-outline color-white btn-custom color-white me-3" (click)="onNo()"
              *ngIf="modalName === 'confirmation-PopUp' || modalName === 'reset_dialog' || modalName == 'deleteFile_dialog'">No</button>
            <button _ngcontent-ng-c918428904="" type="submit" class="btn btn-primary btn-custom" (click)="onYes()"
              *ngIf="modalName === 'confirmation-PopUp' || modalName === 'reset_dialog' || modalName == 'deleteFile_dialog'">Yes</button>
            <button _ngcontent-ng-c918428904="" type="submit" class="btn btn-primary btn-custom" (click)="onSave()"
              *ngIf="modalName == 'set_default_card'">Save</button>
            <button _ngcontent-ng-c918428904="" type="submit" class="btn btn-primary btn-custom" (click)="deleteCard()"
              *ngIf="modalName == 'delete_card'">Delete</button>
            <button _ngcontent-ng-c918428904="" type="submit" class="btn btn-primary btn-custom" (click)="onSavePaymentMethod()"
              *ngIf="modalName == 'set_default_bank'">Save</button>
            <button _ngcontent-ng-c918428904="" type="submit" class="btn btn-primary btn-custom" (click)="deleteBank()"
              *ngIf="modalName == 'delete_bank'">Delete</button>
            <button type="button" class=" btn btn-primary btn-custom" (click)="onInviteUser()"
              *ngIf="modalName === 'invite_user'">Invite</button>
      </div>
    </div>
  </div>
