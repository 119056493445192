<div class="modal-header">
  <h2 class="modal-title text-capitalize" id="reviewModalLabel">Confirm Agency Name</h2>
</div>

<div class="modal-body">
  <form [formGroup]="agencyForm">
    <div class="row">
      <div class="col-md-12">
        <p>Your existing details do not match, please update your details.</p>
        <div class="form-group">
          <label class="form-label" for="agencyName">Agency Name<span class="require-field">*</span></label>
          <div class="d-flex">
            <input type="text" class="form-control" maxlength="100" minlength="2"
              [ngClass]="{'custom-is-invalid': isSubmitted && agencyFormControl['agencyName'].errors}"
              id="agencyName" placeholder="Enter agency name" formControlName="agencyName"
              (input)="onChange($event,'agencyName')">
          </div>
          <div *ngIf="isSubmitted && agencyFormControl['agencyName'].errors" class="form-text text-danger">
            <span *ngIf="agencyFormControl['agencyName'].errors['required']">Please enter agency name.</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-label" for="comments">Comments</label>
          <div class="d-flex">
            <textarea class="form-control" id="comments" placeholder="Enter comments"
              formControlName="comments" (input)="onChange($event,'comments')"></textarea>
          </div>
        </div>
      </div>
    </div> -->
  </form>
</div>

<div class="modal-footer">
    <div>
        <button type="button" class="btn-custom btn-outline me-3" (click)="close()">Close</button>
      </div>  <button type="button" class="btn btn-primary" (click)="update()">Update</button>
</div>
<app-loader [isLoading]="isLoading"></app-loader>


